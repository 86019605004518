import React, { useState } from 'react';
import axios from 'axios';
import Spinner from './Spinner';

const ImageCreationComponent = ({ chat, formToken, user }) => {
  const [prompt, setPrompt] = useState('');
  const [style, setStyle] = useState('cartoon');
  const [difficulty, setDifficulty] = useState('beginner');
  const [layout, setLayout] = useState('1:1');
  const [generatedImage, setGeneratedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [avatarSet, setAvatarSet] = useState(false);
  const [backgroundSet, setBackgroundSet] = useState(false);
  const [chatImageId, setChatImageId] = useState(null);
  const [isPublic, setIsPublic] = useState(false);

  const isColoringPage = chat.chatType === 'coloring_page';
  const isAvatar = chat.chatType === 'avatar_creation';

  const createImage = async () => {
    setLoading(true);

    try {
      const options = isColoringPage
        ? { difficulty }
        : { style, layout: isAvatar ? '1:1' : layout };

      const response = await axios.post(
        `/api/v1/chats/${chat.id}/create_image_from_prompt`,
        {
          message: prompt,
          options,
        },
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
          },
        }
      );

      setGeneratedImage(response.data.imageUrl);
      setChatImageId(response.data.id);
      setIsPublic(response.data.public);
    } catch (error) {
      console.error("Error creating image:", error);
    } finally {
      setLoading(false);
    }
  };

  const setAsAvatar = async () => {
    try {
      const payload = { user: { avatar_id: chatImageId } };
      await axios.patch(
        `/api/v1/users/${user.id}.json`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setAvatarSet(true);
    } catch (error) {
      console.error("Error setting avatar:", error);
    }
  };

  const setAsBackground = async () => {
    try {
      const payload = { user: { background_id: chatImageId } };
      await axios.patch(
        `/api/v1/users/${user.id}.json`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setBackgroundSet(true);
    } catch (error) {
      console.error("Error setting background:", error);
    }
  };

  const toggleShareOnTYB = async () => {
    try {
      const response = await axios.patch(
        `/api/v1/chat_images/${chatImageId}.json`,
        { chat_image: { public: !isPublic } },
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
            'Content-Type': 'application/json'
          },
        }
      );
      setIsPublic(response.data.chat_image.public);
    } catch (error) {
      console.error("Error toggling image visibility:", error);
    }
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = generatedImage;
    link.download = 'tyb_generated_image.png';
    link.click();
  };

  return (
    <div className="bg-white border rounded-lg px-8 py-6 mx-auto my-8 max-w-4xl">
      <h2 className="text-2xl font-medium mb-4">
        {isColoringPage ? 'Create a Coloring Page' : isAvatar ? 'Create Your Avatar' : 'Create Your Image'}
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column: Image Options or Loading Spinner */}
        <div>
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <Spinner />
            </div>
          ) : !generatedImage ? (
            <form className="space-y-4">
              <div className="mb-4">
                <label htmlFor="prompt" className="block text-gray-700 font-medium mb-2">Image Prompt</label>
                <textarea
                  id="prompt"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter your image prompt here..."
                  className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
                  rows="4"
                />
              </div>

              {isColoringPage && (
                <div className="mb-4">
                  <label htmlFor="difficulty" className="block text-gray-700 font-medium mb-2">Difficulty Level</label>
                  <select
                    id="difficulty"
                    value={difficulty}
                    onChange={(e) => setDifficulty(e.target.value)}
                    className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
                  >
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="expert">Expert</option>
                  </select>
                </div>
              )}

              {!isAvatar && (
                <div className="mb-4">
                  <label htmlFor="layout" className="block text-gray-700 font-medium mb-2">Layout</label>
                  <select
                    id="layout"
                    value={layout}
                    onChange={(e) => setLayout(e.target.value)}
                    className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
                  >
                    <option value="1:1">Square</option>
                    <option value="9:16">Portrait</option>
                    <option value="16:9">Landscape</option>
                  </select>
                </div>
              )}

              <div className="mb-4">
                <label htmlFor="style" className="block text-gray-700 font-medium mb-2">Style</label>
                <select
                  id="style"
                  value={style}
                  onChange={(e) => setStyle(e.target.value)}
                  className="border border-gray-400 p-2 w-full rounded-lg focus:outline-none focus:border-blue-400"
                >
                  <option value="cartoon">Cartoon</option>
                  <option value="watercolor">Watercolor</option>
                  <option value="realistic">Realistic</option>
                  <option value="pencil_sketch">Pencil Sketch</option>
                  <option value="charcoal">Charcoal</option>
                  <option value="oil_painting">Oil Painting</option>
                  <option value="stencil">Stencil</option>
                  <option value="papercraft">Papercraft</option>
                  <option value="marker">Marker Illustration</option>
                  <option value="risograph">Risograph</option>
                  <option value="graffiti">Graffiti</option>
                  <option value="ink_wash">Ink Wash</option>
                  <option value="collage">Collage</option>
                  <option value="mosaic">Mosaic</option>
                </select>
              </div>

              <div>
                <button
                  type="button"
                  onClick={createImage}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 w-full"
                >
                  Create Image
                </button>
              </div>
            </form>
          ) : null}
        </div>

        {/* Right Column: Display Generated Image or Placeholder */}
        <div className="flex flex-col justify-center items-center">
          {generatedImage ? (
            <div className="w-full">
              <h3 className="text-xl font-medium mb-4">Generated Image</h3>
              <img
                src={generatedImage}
                alt="Generated artwork"
                className="border border-gray-400 rounded-lg max-w-full mb-4"
              />
              <div className="space-y-2">
                <button
                  onClick={downloadImage}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 w-full"
                >
                  Download Image
                </button>
                {!isAvatar && (
                  <button
                    onClick={setAsBackground}
                    className={`w-full px-4 py-2 rounded-lg ${
                      backgroundSet ? 'bg-gray-500 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'
                    } text-white`}
                    disabled={backgroundSet}
                  >
                    {backgroundSet ? 'Background Set' : 'Set as Background'}
                  </button>
                )}
                {isAvatar && (
                  <button
                    onClick={setAsAvatar}
                    className={`w-full px-4 py-2 rounded-lg ${
                      avatarSet ? 'bg-gray-500 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'
                    } text-white`}
                    disabled={avatarSet}
                  >
                    {avatarSet ? 'Avatar Set' : 'Set as Avatar'}
                  </button>
                )}
                <button
                  onClick={toggleShareOnTYB}
                  className={`w-full px-4 py-2 rounded-lg ${
                    isPublic ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'
                  } text-white`}
                >
                  {isPublic ? 'Hide Image' : 'Share On TYB'}
                </button>
              </div>
            </div>
          ) : (
            <div className="text-gray-500">
              {loading ? 'Generating your image...' : 'Your image will appear here once generated.'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageCreationComponent;