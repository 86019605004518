import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { useCounterStore, useChatStore } from '../../../store';

const HelloWorld = (props) => {
  const [name, setName] = useState(props.name);
  const { count, add, subtract } = useCounterStore((state) => state);
  const chat = useChatStore((state) => state);
  useEffect(() => {
    chat.fetch();
  }, []);

  return (
    <div>
      <h1>github api example:</h1>
      {chat.chat && (
        <img
          className='rounded-full max-w-28'
          src={chat.chat.avatar_url}
          alt='avatar'
        />
      )}
      <span>{chat.chat && chat.chat.login}</span>
      <hr />
      <form>
        <h1>State management example:</h1>
        <div className='text-lg flex items-center'>
          <button
            type='button'
            className='flex items-center border-2 border-blue-500 rounded-md px-4 py-2'
            onClick={() => subtract()}
          >
            <FaMinus color='blue' className='mr-2' />
            Subtract
          </button>
          <span className='mx-5'>{count}</span>
          <button
            type='button'
            className=' flex items-center border-2 border-blue-500 rounded-md px-4 py-2'
            onClick={add}
          >
            <FaPlus color='blue' className='mr-2' />
            Add
          </button>
        </div>
        <label className=' text-3xl' htmlFor='name'>
          <h2>{props.title}</h2>

          <hr />
          <input
            id='name'
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
      </form>
    </div>
  );
};

HelloWorld.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
  title: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default HelloWorld;
