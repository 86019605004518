import { create } from "zustand";

export const useCounterStore = create((set) => ({
  count: 0,
  add: () => set((state) => ({ count: state.count + 1 })),
  subtract: () => set((state) => ({ count: state.count - 1 })),
}));

export const useChatStore = create((set) => ({
  chat: null,
  fetch: async () => {
    const response = await fetch("https://api.github.com/orgs/nodejs");
    set({ chat: await response.json() });
  },
}));
