import React, { useState, useEffect } from "react";
import axios from "axios";
import useInfiniteImageLoad from "./useInfiniteImageLoad";

const ImageGrid = ({ initialImages, totalCount, formToken, user, editType }) => {
  const { images: hookImages, loading, hasMore } = useInfiniteImageLoad(initialImages, totalCount, formToken);
  const [images, setImages] = useState(initialImages);

  useEffect(() => {
    setImages(hookImages);
  }, [hookImages]);

  const [completedImageId, setCompletedImageId] = useState(null);

  const handleSetAsAction = async (imageId) => {
    const payload = editType === 'avatar' ? { user: { avatar_id: imageId } } : { user: { background_id: imageId } };

    try {
      const response = await axios.patch(
        `/api/v1/users/${user.id}.json`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCompletedImageId(imageId);
      // Update the user object in the parent component if necessary
    } catch (error) {
      console.error(`Error setting ${editType}:`, error);
    }
  };

  const handleShareImage = async (imageId) => {
    try {
      const response = await axios.patch(
        `/api/v1/chat_images/${imageId}.json`,
        { chat_image: { public: !images.find(img => img.id === imageId).public } },
        {
          headers: {
            Authorization: `Bearer ${formToken}`,
            'Content-Type': 'application/json'
          },
        }
      );
      
      // Assuming the response.data contains the updated image object
      const updatedImage = response.data.chat_image;
      // Update the images array, preserving existing data and updating only the changed fields
      setImages(prevImages => prevImages.map(img => 
        img.id === updatedImage.id ? updatedImage : img
      ));
    } catch (error) {
      console.error("Error toggling image visibility:", error);
    }
  };

  const downloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'downloaded_image.png';
    link.click();
  };

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
        {images.map((image) => (
          <div key={`image-${image.id}`} className="rounded overflow-hidden shadow-lg">
            <a href={image.chatUrl}>
              <img className="w-full" src={image.imageUrl} alt="User generated" />
            </a>
            <div className="px-6 py-4">
              <p className="text-gray-500 font-bold text-xl mb-2">Created By {image.createdBy}</p>
              <p className="text-gray-500 text-sm">{image.created_at}</p>
              {editType && (
                <button
                  onClick={() => handleSetAsAction(image.id)}
                  className={`mt-2 px-4 py-2 rounded-lg uppercase ${
                    completedImageId === image.id ? 'bg-gray-500 cursor-not-allowed' : 'bg-indigo-500 hover:bg-indigo-600'
                  } text-white`}
                  disabled={completedImageId === image.id}
                >
                  {completedImageId === image.id ? `${editType} set` : `set as ${editType}`}
                </button>
              )}
              {user.id === image.createdUser && image.role === 'assistant' && (
                <button
                  onClick={() => handleShareImage(image.id)}
                  className={`mt-2 mr-2 px-4 py-2 rounded-lg ${
                    image.public ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'
                  } text-white`}
                >
                  {image.public ? 'Hide Image' : 'Share On TYB'}
                </button>
              )}
              <button
                onClick={() => downloadImage(image.imageUrl)}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 mt-2"
              >
                Download Image
              </button>
            </div>
          </div>
        ))}
      </div>
      {loading && <h3>Loading more images...</h3>}
      {!hasMore && <span></span>}
      {hasMore && (
        <div id="loading-element" className="py-10 text-center text-gray-500">
          Scroll down for more images...
        </div>
      )}
    </div>
  );
};

export default ImageGrid;
