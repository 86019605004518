import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import StoryTellingChat from "./StoryTellingChat";
import MessageInput from "./MessageInput";
import { Message } from "./Message";
import Spinner from "./Spinner";
import LanguageLearningPrompts from './LanguageLearningPrompts';

const Chat = (props) => {
  const [messages, setMessages] = useState(props.chat.messages);
  const [input, setInput] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [isStorySubmitted, setIsStorySubmitted] = useState(
    props.chat.chatType === "story_telling" && !!props.chat.chatStory
  );
  const [isLoading, setIsLoading] = useState(false);
  const [subject, setSubject] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [learningMode, setLearningMode] = useState(null);

  const handleFileChange = (file) => {
    setImageFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let uploadedImageURL = null;
    let uploadedImageId = null;

    if (imageFile) {
      const formData = new FormData();
      formData.append("chat_image[role]", "user");
      formData.append("chat_image[image]", imageFile);

      try {
        const response = await axios.post(
          `/chats/${props.chat.id}/chat_images`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${props.formToken}`,
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          }
        );
        uploadedImageURL = response.data.imageURL;
        uploadedImageId = response.data.imageId;
      } catch (error) {
        console.error("Image upload failed:", error);
        return; // Prevent sending the message if image upload fails
      }
    }

    const userMessage = {
      role: "user",
      content: input,
      image_url: uploadedImageURL,
      image_id: uploadedImageId,
    };

    setMessages([...messages, userMessage]);
    setInput("");
    setImageFile(null);
    setIsLoading(true);

    try {
      const response = await axios.post(
        `/api/v1/chats/${props.chat.id}/send_message`,
        {
          content: input,
          image_id: uploadedImageId,
          subject: subject || "anything",
          ...(props.chat.chatType === 'language_learning' && {
            language: targetLanguage?.code
          })
        },
        {
          headers: {
            Authorization: `Bearer ${props.formToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const assistantMessage = {
        role: "assistant",
        id: response.data.id,
        audioUrl: response.data.audio_url,
        explanation: response.data.explanation,
        examples: response.data.examples || [],
        equations: response.data.equations || [],
        follow_up: response.data.follow_up || '',
      };

      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const regularSubjects = ["Math", "Science", "History", "Geography", "Language Arts", "Anything"];

  const languageModes = [
    { id: 'conversation', label: 'Have a Conversation', icon: '💭' },
    { id: 'vocabulary', label: 'Learn New Words', icon: '📚' },
    { id: 'grammar', label: 'Practice Grammar', icon: '✏️' },
    { id: 'pronunciation', label: 'Pronunciation Help', icon: '🗣️' },
  ];

  const languages = [
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'it', name: 'Italian' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'nl', name: 'Dutch' },
  ];

  const isNewChat = messages.length === 0;

  const handleFollowUpClick = (question) => {
    setInput(question);
  };

  return (
    <div className="mx-auto py-4 h-screen flex flex-col">
      {isNewChat && props.chat.chatType === 'language_learning' ? (
        <div className="mb-4 space-y-6">
          {!targetLanguage && (
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-bold mb-4">What language would you like to learn?</h2>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {languages.map((lang) => (
                  <button
                    key={lang.code}
                    onClick={() => setTargetLanguage(lang)}
                    className="p-4 text-lg rounded-lg border-2 hover:border-blue-500 hover:bg-blue-50 transition-colors
                             flex items-center justify-center space-x-2"
                  >
                    <span>{lang.name}</span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {targetLanguage && !learningMode && (
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-xl font-bold mb-4">What would you like to do?</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {languageModes.map((mode) => (
                  <button
                    key={mode.id}
                    onClick={() => setLearningMode(mode)}
                    className="p-6 text-lg rounded-lg border-2 hover:border-blue-500 hover:bg-blue-50 transition-colors
                             flex items-center justify-center space-x-3"
                  >
                    <span className="text-2xl">{mode.icon}</span>
                    <span>{mode.label}</span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {targetLanguage && learningMode && (
            <LanguageLearningPrompts 
              language={targetLanguage}
              mode={learningMode}
              onPromptSelect={(prompt) => setInput(prompt)}
            />
          )}
        </div>
      ) : isNewChat && !isStorySubmitted && props.chat.chatType !== 'language_learning' ? (
        <div className="mb-4">
          <h2 className="text-xl font-bold mb-2 bg-white p-2 rounded-lg">Choose a subject:</h2>
          <div className="flex flex-wrap gap-2 p-2 bg-white rounded-lg">
            {regularSubjects.map((sub) => (
              <button
                key={sub}
                onClick={() => setSubject(sub)}
                className={`px-4 py-2 rounded ${
                  subject === sub
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {sub}
              </button>
            ))}
          </div>
        </div>
      ) : null}

      <div className="mb-4 overflow-y-auto" style={{ maxHeight: "calc(100vh - 200px)" }}>
        {isNewChat && !isStorySubmitted ? (
          <div className="text-center mt-4 text-gray-500 bg-white p-4 rounded-lg">
            {props.chat.chatType === 'language_learning' 
              ? (!targetLanguage 
                  ? "Choose a language to start learning!"
                  : !learningMode 
                    ? "Select what you'd like to practice!"
                    : "Pick a prompt to start chatting or type your own!")
              : (subject 
                  ? "Type your message to start the chat!" 
                  : "Select a subject and start your chat!")}
          </div>
        ) : (
          messages.map((message, messageIndex) => (
            <Message
              message={message}
              user={props.user}
              key={`${messageIndex}-messages`}
              onFollowUpClick={handleFollowUpClick}
              formToken={props.formToken}
            />
          ))
        )}
        {isLoading && (
          <div className="flex justify-center items-center mt-4">
            <Spinner />
          </div>
        )}
      </div>

      {!props.readOnly &&
      props.chat.chatType !== "story_telling" &&
      !isStorySubmitted && (
        <MessageInput
          handleSubmit={handleSubmit}
          input={input}
          setInput={setInput}
          handleFileChange={handleFileChange}
          user={props.user}
          showWork={props.chat.chatType === "show_work"}
          messageCount={messages.length}
          disabled={isNewChat && (
            props.chat.chatType === 'language_learning' 
              ? !targetLanguage || !learningMode
              : !subject
          )}
        />
      )}

      {props.chat.chatType === "story_telling" && (
        <StoryTellingChat
          formToken={props.formToken}
          onSaveChat={() => setIsStorySubmitted(true)}
          chatId={props.chat.id}
          chatStory={props.chat.chatStory}
        />
      )}

      {!isNewChat && !isStorySubmitted && (
        <div className="text-center mt-5">
          <a className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" href={`/chats/new?chat_type=${props.chat.chatType}`}>
            Start A New Chat
          </a>
        </div>
      )}
    </div>
  );
};

Chat.propTypes = {
  chatKey: PropTypes.number,
  name: PropTypes.string,
  chat: PropTypes.shape({
    id: PropTypes.number.isRequired,
    chatType: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        role: PropTypes.string.isRequired,
        content: PropTypes.string,
        image_url: PropTypes.string,
      })
    ).isRequired,
    chatStory: PropTypes.object,
  }).isRequired
};

export default Chat;
