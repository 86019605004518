import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const useInfiniteImageLoad = (initialImages, totalCount, formToken) => {
  const [images, setImages] = useState(initialImages);
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(initialImages.length < totalCount);
  const observerRef = useRef();

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && hasMore && !loading) {
          loadMoreImages();
        }
      },
      { threshold: 1.0 }
    );

    const target = document.getElementById("loading-element");
    if (target) observer.observe(target);

    observerRef.current = observer;
    return () => observer.disconnect();
  }, [hasMore, loading]);

  const loadMoreImages = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get("/chat_images.json", {
        params: { page },
        headers: {
          Authorization: `Bearer ${formToken}`,
        },
      });

      if (response.data.images.length > 0) {
        setImages((prevImages) => [...prevImages, ...response.data.images]);
        setPage((prevPage) => prevPage + 1);
      }

      if (!response.data.next_page || images.length >= totalCount) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more images:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  return { images, loading, hasMore };
};

export default useInfiniteImageLoad;