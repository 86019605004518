import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Spinner from "../../Chat/components/Spinner";

const WordList = ({ wordList, initialUser }) => {
  const [words, setWords] = useState(wordList.words || []);
  const [newWord, setNewWord] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(initialUser);

  const addWord = async (e) => {
    e.preventDefault();
    if (!newWord.trim() || isLoading || user.wordsRemaining <= 0) return;

    setIsLoading(true);
    try {
      const response = await axios.post(`/api/v1/word_lists/${wordList.id}/words`, { word: newWord });
      const addedWord = response.data.word;
      setNewWord("");
      setUser(response.data.user);
      
      // Immediately add the new word to the list
      setWords(prevWords => [...prevWords, addedWord]);

      // Fetch the updated word list to ensure consistency
      const updatedWordListResponse = await axios.get(`/api/v1/word_lists/${wordList.id}`);
      setWords(updatedWordListResponse.data.words);
    } catch (error) {
      console.error("Error adding word:", error);
      // TODO: Add error handling UI
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWord = async (wordId) => {
    try {
      await axios.delete(`/api/v1/word_lists/${wordList.id}/words/${wordId}`);
      setWords(words.filter(word => word.id !== wordId));
    } catch (error) {
      console.error("Error deleting word:", error);
      // TODO: Add error handling UI
    }
  };

  const playSlowAudio = (audioUrl, rate = 0.75) => {
    const audio = new Audio(audioUrl);
    audio.playbackRate = rate;
    audio.play();
  };

  return (
    <div className="space-y-6">
      <div className="bg-blue-100 p-4 rounded-lg">
        <p className="text-lg font-semibold">Language: <span className="text-blue-600">{wordList.languageName}</span></p>
      </div>

      {user.wordsRemaining > 0 ? (
        <form onSubmit={addWord} className="flex space-x-2">
          <input
            type="text"
            value={newWord}
            onChange={(e) => setNewWord(e.target.value)}
            placeholder="Enter a new word"
            className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading}
          />
          <button 
            type="submit" 
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Add Word"}
          </button>
        </form>
      ) : (
        <p className="text-red-500">You have reached the maximum number of words for this week.  Please upgrade to add more or wait until next week.</p>
      )}
      
      {user.freeTools && (
        <p className="text-sm text-gray-600">Words remaining: {user.wordsRemaining}</p>
      )}

      <ul className="space-y-2">
        {words.map((word) => (
          <li key={word.id} className="flex flex-col bg-gray-100 p-3 rounded-lg">
            <div className="flex items-center justify-between">
              <span className="font-semibold">{word.word_text}</span>
              <button
                onClick={() => deleteWord(word.id)}
                className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Delete
              </button>
            </div>
            <div className="mt-2 space-y-2">
              {word.audioWordUrl && (
                <div>
                  <label htmlFor={`say-word-${word.id}`} className="block text-sm font-medium text-gray-700">
                    Say word:
                  </label>
                  <div className="flex items-center space-x-2 mt-1">
                    <audio
                      id={`say-word-${word.id}`}
                      src={word.audioWordUrl}
                      controls
                      className="flex-grow"
                    />
                    <button
                      onClick={() => playSlowAudio(word.audioWordUrl)}
                      className="px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      Play Slow
                    </button>
                  </div>
                </div>
              )}
              {word.sentence && (
                <p className="mt-2 text-gray-600 italic">{word.sentence}</p>
              )}
              {word.audioSentenceUrl && (
                <div>
                  <label htmlFor={`read-sentence-${word.id}`} className="block text-sm font-medium text-gray-700">
                    Read sentence:
                  </label>
                  <div className="flex items-center space-x-2 mt-1">
                    <audio
                      id={`read-sentence-${word.id}`}
                      src={word.audioSentenceUrl}
                      controls
                      className="flex-grow"
                    />
                    <button
                      onClick={() => playSlowAudio(word.audioSentenceUrl)}
                      className="px-3 py-1 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      Play Slow
                    </button>
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

WordList.propTypes = {
  wordList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    languageName: PropTypes.string.isRequired,
    words: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      word_text: PropTypes.string.isRequired,
      // ... other word properties ...
    })),
  }).isRequired,
  initialUser: PropTypes.shape({
    wordsRemaining: PropTypes.number.isRequired,
    freeTools: PropTypes.bool.isRequired,
    // ... other user properties ...
  }).isRequired,
};

export default WordList;
