import React from "react";

const UserMessage = ({ message }) => {
  return (
    <div className="p-3 rounded-lg bg-blue-500 text-white w-full">
      {message.content}
      {message.image_url && (
        <div>
          <img
            src={message.image_url}
            alt="Uploaded content"
            className="mt-2"
            width={200}
          />
        </div>
      )}
    </div>
  );
};

export default UserMessage;