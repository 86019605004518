import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const specialCharacters = {
  es: ['á', 'é', 'í', 'ó', 'ú', 'ñ', 'ü', '¿', '¡'],
  fr: ['é', 'è', 'ê', 'ë', 'à', 'â', 'ô', 'û', 'ù', 'ï', 'ç'],
  de: ['ä', 'ö', 'ü', 'ß'],
  it: ['à', 'è', 'é', 'ì', 'ò', 'ù'],
  pt: ['á', 'â', 'ã', 'à', 'ç', 'é', 'ê', 'í', 'ó', 'ô', 'õ', 'ú'],
  nl: ['ij', 'á', 'é', 'í', 'ó', 'ú']
};

const WordTest = ({ wordList }) => {
  const [words, setWords] = useState(wordList.words || []);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [wrongAnswers, setWrongAnswers] = useState([]);
  const [earnedCoins, setEarnedCoins] = useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showWrongAnswersSummary, setShowWrongAnswersSummary] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [retryMode, setRetryMode] = useState(false);

  const currentWord = words[currentWordIndex];
  const languageCode = wordList.language.toLowerCase();

  const handleSpecialCharacter = (char) => {
    setUserAnswer(prevAnswer => prevAnswer + char);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasSubmitted) return;

    const correct = userAnswer.toLowerCase().trim() === currentWord.word_text.toLowerCase().trim();
    setIsCorrect(correct);
    setShowResult(true);
    setHasSubmitted(true);

    if (correct) {
      setCorrectAnswers(prev => [...prev, currentWord]);
      try {
        const response = await fetch('/api/v1/user_rewards/add_coin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content,
          }
        });
        
        if (response.ok) {
          setEarnedCoins(prev => prev + 1);
        }
      } catch (error) {
        console.error('Error adding coin:', error);
      }
    } else {
      setWrongAnswers(prev => [...prev, {
        word: currentWord,
        userAnswer: userAnswer
      }]);
    }
  };

  const nextWord = () => {
    const isLastWord = currentWordIndex === words.length - 1;
    
    if (isLastWord && wrongAnswers.length > 0) {
      setShowWrongAnswersSummary(true);
    } else {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      setUserAnswer("");
      setShowResult(false);
      setHasSubmitted(false);
    }
  };

  const playAudio = (audioUrl, rate = 1) => {
    const audio = new Audio(audioUrl);
    audio.playbackRate = rate;
    audio.play();
  };

  const resetFullTest = () => {
    setWords(wordList.words || []);
    setCurrentWordIndex(0);
    setUserAnswer("");
    setShowResult(false);
    setIsCorrect(false);
    setWrongAnswers([]);
    setCorrectAnswers([]);
    setEarnedCoins(0);
    setHasSubmitted(false);
    setShowWrongAnswersSummary(false);
    setRetryMode(false);
  };

  const retryWrongAnswers = () => {
    setWords(wrongAnswers.map(wa => wa.word));
    setCurrentWordIndex(0);
    setUserAnswer("");
    setShowResult(false);
    setIsCorrect(false);
    setWrongAnswers([]);
    setHasSubmitted(false);
    setShowWrongAnswersSummary(false);
    setRetryMode(true);
  };

  if (showWrongAnswersSummary) {
    return (
      <div className="space-y-6">
        <div className="bg-blue-100 p-4 rounded-lg">
          <h2 className="text-xl font-bold mb-4">Test Complete!</h2>
          <p className="text-lg">
            You got {earnedCoins} out of {retryMode ? wrongAnswers.length : words.length} words correct!
          </p>

          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Words You Got Right:</h3>
            <div className="space-y-2">
              {correctAnswers.map((correct, index) => (
                <div key={index} className="bg-white p-3 rounded-lg shadow border-l-4 border-green-500">
                  <p className="font-medium text-green-600">{correct.word_text}</p>
                  <p className="text-sm text-gray-600 mt-1">Context: {correct.sentence}</p>
                  <button
                    onClick={() => playAudio(correct.audioWordUrl)}
                    className="mt-2 px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Listen
                  </button>
                </div>
              ))}
            </div>
          </div>

          {wrongAnswers.length > 0 && (
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Words to Review:</h3>
              <div className="space-y-2">
                {wrongAnswers.map((wrong, index) => (
                  <div key={index} className="bg-white p-3 rounded-lg shadow">
                    <p className="font-medium">Correct: <span className="text-green-600">{wrong.word.word_text}</span></p>
                    <p className="text-sm text-gray-600">Your answer: <span className="text-red-600">{wrong.userAnswer}</span></p>
                    <p className="text-sm text-gray-600 mt-1">Context: {wrong.word.sentence}</p>
                    <button
                      onClick={() => playAudio(wrong.word.audioWordUrl)}
                      className="mt-2 px-3 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      Listen Again
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mt-6 space-x-4">
            <button
              onClick={retryWrongAnswers}
              className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
              disabled={wrongAnswers.length === 0}
            >
              Retry Incorrect Words
            </button>
            <button
              onClick={resetFullTest}
              className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
            >
              Start New Test
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-blue-100 p-4 rounded-lg">
        <p className="text-lg font-semibold">Language: <span className="text-blue-600">{wordList.languageName}</span></p>
        <p className="text-lg font-semibold">Word: <span className="text-blue-600">{currentWordIndex + 1} / {words.length}</span></p>
      </div>

      <div className="space-y-4">
        <div className="flex space-x-2">
          <button
            onClick={() => playAudio(currentWord.audioWordUrl)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Say Word
          </button>
          <button
            onClick={() => playAudio(currentWord.audioWordUrl, 0.75)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Say Word Slowly
          </button>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => playAudio(currentWord.audioSentenceUrl)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Say Sentence
          </button>
          <button
            onClick={() => playAudio(currentWord.audioSentenceUrl, 0.5)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Say Sentence Slowly
          </button>
        </div>
        {currentWord.sentence && (
          <p className="mt-2 text-gray-600 italic">
            {currentWord.sentence.replace(new RegExp(currentWord.word_text, 'gi'), '______')}
          </p>
        )}
      </div>

      <form onSubmit={handleSubmit} className="flex flex-col space-y-2">
        <input
          type="text"
          value={userAnswer}
          onChange={(e) => setUserAnswer(e.target.value)}
          placeholder="Enter the word"
          disabled={hasSubmitted}
          className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-500"
        />
        {specialCharacters[languageCode] && (
          <div className="flex flex-wrap gap-2 mt-2">
            {specialCharacters[languageCode].map((char, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handleSpecialCharacter(char)}
                disabled={hasSubmitted}
                className="px-3 py-1 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {char}
              </button>
            ))}
          </div>
        )}
        <button 
          type="submit" 
          disabled={hasSubmitted}
          className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Submit Answer
        </button>
      </form>

      {showResult && (
        <div className={`p-4 rounded-lg ${isCorrect ? 'bg-green-100' : 'bg-red-100'}`}>
          <p className={`text-lg font-semibold ${isCorrect ? 'text-green-600' : 'text-red-600'}`}>
            {isCorrect ? 'Correct!' : `Incorrect. The correct word is: ${currentWord.word_text}`}
          </p>
          <button
            onClick={nextWord}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            {currentWordIndex === words.length - 1 ? 'Finish Test' : 'Next Word'}
          </button>
        </div>
      )}
    </div>
  );
};

WordTest.propTypes = {
  wordList: PropTypes.shape({
    id: PropTypes.number.isRequired,
    language: PropTypes.string.isRequired,
    words: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      word_text: PropTypes.string.isRequired,
      sentence: PropTypes.string.isRequired,
      audioWordUrl: PropTypes.string.isRequired,
      audioSentenceUrl: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default WordTest;
