import React, { useState, useEffect } from "react";
import axios from "axios";
import ImageSection from "./ImageSection";

const StoryTellingChat = ({ formToken, onSaveChat, chatId, chatStory }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storyParts, setStoryParts] = useState(chatStory?.storyParts || []);
  const [chatStoryId, setChatStoryId] = useState(chatStory?.id || null);
  const [userPrompt, setUserPrompt] = useState(chatStory?.prompt || '');
  const [audioStatus, setAudioStatus] = useState({});

  const handleSendStoryPrompt = async (prompt) => {
    setIsSubmitting(true);
    setUserPrompt(prompt);
    try {
      const queryParams = new URLSearchParams({ prompt }).toString();

      const response = await axios.post(
        `/api/v1/chats/${chatId}/tell_story?${queryParams}`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${formToken}`,
          },
        }
      );
      setChatStoryId(response.data.id);
      const storySegments = response.data.sections;
      setStoryParts(storySegments);
      onSaveChat();
    } catch (error) {
      console.error("Error generating story:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGenerateAudio = async (messageId) => {
    setAudioStatus(prev => ({ ...prev, [messageId]: 'generating' }));
    try {
      const response = await axios.post(
        `/api/v1/chat_story_sections/${messageId}/generate_audio`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${formToken}`,
          },
        }
      );
      setAudioStatus(prev => ({ ...prev, [messageId]: 'ready' }));
      // Refresh the story parts to get the updated audio URL
      const updatedStoryParts = storyParts.map(part => 
        part.id === messageId ? { ...part, audioUrl: response.data.audio_url } : part
      );
      setStoryParts(updatedStoryParts);
    } catch (error) {
      console.error("Error generating audio:", error);
      setAudioStatus(prev => ({ ...prev, [messageId]: 'error' }));
    }
  };

  useEffect(() => {
    if (chatStory) {
      setStoryParts(chatStory.storyParts.map(part => ({
        ...part,
        audioUrl: part.audioUrl || null // Ensure audioUrl is set, even if it's null
      })));
      setChatStoryId(chatStory.id);
      setUserPrompt(chatStory.prompt);
      
      // Set initial audio status for parts with existing audio
      const initialAudioStatus = {};
      chatStory.storyParts.forEach(part => {
        initialAudioStatus[part.id] = part.audioUrl ? 'ready' : 'not_generated';
      });
      setAudioStatus(initialAudioStatus);
    }
  }, [chatStory]);

  return (
    <div className="w-full max-w-3xl mx-auto">
      {!storyParts.length && (
        <div className="mb-6">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const prompt = e.target.prompt.value;
              handleSendStoryPrompt(prompt);
            }}
            className="w-full bg-white rounded-lg shadow-md p-6"
          >
            <label htmlFor="prompt" className="block mb-2 text-lg font-medium text-gray-700">
              What question or topic do you want me to write a story about?
            </label>
            <textarea
              id="prompt"
              name="prompt"
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows={4}
              placeholder="Type your question or topic here..."
              disabled={isSubmitting}
            ></textarea>
            <button
              type="submit"
              className="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Generating Story..." : "Submit"}
            </button>
          </form>
        </div>
      )}

      {storyParts.length > 0 && (
        <div className="space-y-6">
          <div className="bg-blue-100 p-4 rounded-lg shadow-md">
            <h4 className="font-bold text-lg mb-2">Original Question:</h4>
            <p className="text-gray-700">{userPrompt}</p>
          </div>
          {storyParts.map((part, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
              <p className="mb-4 text-xl font-semibold text-gray-800">{part.content}</p>
              <div className="mb-4">
                {part.audioUrl ? (
                  <audio controls src={part.audioUrl} className="w-full" />
                ) : (
                  <button
                    onClick={() => handleGenerateAudio(part.id)}
                    className="w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-200"
                    disabled={audioStatus[part.id] === 'generating'}
                  >
                    {audioStatus[part.id] === 'generating' ? 'Generating Audio...' : 'Listen To Audio'}
                  </button>
                )}
              </div>
              <ImageSection 
                imageId={part.imageId || ''} 
                image={part.image || ''} 
                formToken={formToken} 
                chatId={chatId} 
                chatStoryId={chatStoryId} 
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StoryTellingChat;
