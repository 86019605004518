import React from 'react';
import PropTypes from 'prop-types';

const LanguageLearningPrompts = ({ language, mode, onPromptSelect }) => {
  const getPrompts = () => {
    switch (mode.id) {
      case 'conversation':
        return [
          "Hi! Can we practice having a simple conversation?",
          "Could you help me introduce myself?",
          "Let's talk about hobbies and interests",
        ];
      case 'vocabulary':
        return [
          "Can you teach me some basic words about animals?",
          "I'd like to learn words about food and drinks",
          "What are some common everyday phrases?",
        ];
      case 'grammar':
        return [
          "Can you help me practice present tense?",
          "I need help with question words",
          "Let's practice basic sentence structure",
        ];
      case 'pronunciation':
        return [
          "Can you help me pronounce some basic words?",
          "What are the most important sounds in this language?",
          "Let's practice saying common phrases",
        ];
      default:
        return [];
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">
        Suggested ways to start:
      </h3>
      <div className="space-y-3">
        {getPrompts().map((prompt, index) => (
          <button
            key={index}
            onClick={() => onPromptSelect(prompt)}
            className="w-full text-left p-3 rounded-lg hover:bg-blue-50 border border-gray-200
                     transition-colors duration-200"
          >
            {prompt}
          </button>
        ))}
      </div>
    </div>
  );
};

LanguageLearningPrompts.propTypes = {
  language: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  mode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  onPromptSelect: PropTypes.func.isRequired,
};

export default LanguageLearningPrompts;
