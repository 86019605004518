import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaStar } from 'react-icons/fa';
import { HiSpeakerWave, HiOutlineSpeakerWave } from 'react-icons/hi2';
import { ImSpinner8 } from 'react-icons/im';
import axios from 'axios';

const CollapsibleSection = ({ title, items, isOpen, toggleOpen, onItemClick, isClickable }) => {
  if (!items || items.length === 0) return null;

  return (
    <div className="mt-3">
      <button
        onClick={toggleOpen}
        className="flex items-center text-indigo-600 hover:text-indigo-800 
                  bg-indigo-50 hover:bg-indigo-100 rounded-full px-4 py-2 
                  transition-all duration-200 text-sm font-medium"
      >
        {isOpen ? <FaChevronUp className="mr-2" /> : <FaChevronDown className="mr-2" />}
        {title} <FaStar className="ml-2 text-yellow-400" />
      </button>
      {isOpen && (
        <div className="mt-2 pl-4 border-l-4 border-indigo-200 space-y-2">
          {items.map((item, index) => (
            <div 
              key={index} 
              className={`p-2 rounded-lg ${
                isClickable 
                  ? 'bg-blue-50 hover:bg-blue-100 cursor-pointer transition-colors duration-200' 
                  : 'bg-purple-50'
              }`}
              onClick={() => isClickable && onItemClick(item)}
              role={isClickable ? "button" : "none"}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AssistantMessage = ({ message, onFollowUpClick, formToken }) => {
  const [audioStatus, setAudioStatus] = useState('not_generated');
  const [audioUrl, setAudioUrl] = useState(message.audio_url);
  const [showExamples, setShowExamples] = useState(false);
  const [showEquations, setShowEquations] = useState(false);
  const [showFollowUp, setShowFollowUp] = useState(false);

  const handleGenerateAudio = async () => {
    setAudioStatus('generating');
    try {
      const response = await axios.post(
        `/api/v1/chat_messages/${message.id}/generate_audio`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${formToken}`,
          },
        }
      );
      setAudioUrl(response.data.audio_url);
      setAudioStatus('ready');
    } catch (error) {
      console.error("Error generating audio:", error);
      setAudioStatus('error');
    }
  };

  return (
    <div className="bg-gradient-to-br from-white to-indigo-50 rounded-xl p-6 shadow-lg border border-indigo-100">
      <div className="prose max-w-none">
        <div className="flex justify-between items-start mb-4">
          <div className="text-gray-800 whitespace-pre-wrap leading-relaxed flex-grow">
            {message.explanation}
          </div>
          <div className="ml-4 flex-shrink-0">
            {audioUrl ? (
              <div className="bg-indigo-50 rounded-lg p-2">
                <audio 
                  controls 
                  src={audioUrl} 
                  className="h-8 w-32"
                />
              </div>
            ) : (
              <button
                onClick={handleGenerateAudio}
                className="flex items-center gap-2 text-indigo-600 hover:text-indigo-800 
                         bg-indigo-50 hover:bg-indigo-100 rounded-full px-4 py-2
                         transition-all duration-200 text-sm font-medium"
                disabled={audioStatus === 'generating'}
              >
                {audioStatus === 'generating' ? (
                  <>
                    <ImSpinner8 className="animate-spin h-5 w-5" />
                    <span>Reading...</span>
                  </>
                ) : (
                  <>
                    <HiSpeakerWave className="h-5 w-5" />
                    <span>Hear it!</span>
                  </>
                )}
              </button>
            )}
          </div>
        </div>

        <CollapsibleSection
          title="Examples that might help."
          items={message.examples}
          isOpen={showExamples}
          toggleOpen={() => setShowExamples(!showExamples)}
          isClickable={false}
        />

        <CollapsibleSection
          title="Helpful Equations to break it down."
          items={message.equations}
          isOpen={showEquations}
          toggleOpen={() => setShowEquations(!showEquations)}
          isClickable={false}
        />

        <CollapsibleSection
          title="Try These Other Questions to Learn More."
          items={message.follow_up}
          isOpen={showFollowUp}
          toggleOpen={() => setShowFollowUp(!showFollowUp)}
          onItemClick={onFollowUpClick}
          isClickable={true}
        />
      </div>
    </div>
  );
};

export default AssistantMessage;
