import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import StoryTellingChat from "./StoryTellingChat";
import MessageInput from "./MessageInput";
import { Message } from "./Message";

const Chat = (props) => {
  const [input, setInput] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [eventSource, setEventSource] = useState(null);
  const [isStorySubmitted, setIsStorySubmitted] = useState(
    props.chat.chatType === "story_telling" && !!props.chat.chatStory.story
  );

  const handleSend = async (e) => {
    e.preventDefault();

    const userMessage = {
      role: "user",
      content: input,
      image_url: imageURL,
      image_id: imageId,
    };

    setMessages([...messages, userMessage]);
    setInput("");
    if (eventSource) {
      eventSource.close();
    }

    const queryParams = new URLSearchParams({
      content: input,
      image_id: imageId,
    }).toString();
    const newEventSource = new EventSource(
      `/api/v1/chats/${props.chat.id}/send_message?${queryParams}`
    );
    setEventSource(newEventSource);
    setImageURL(null);
    let assistantMessage = { role: "assistant", content: "" };
    setMessages((prevMessages) => [...prevMessages, assistantMessage]);

    newEventSource.onmessage = (event) => {
      const newContent = event.data;
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessageIndex = updatedMessages.length - 1;

        updatedMessages[lastMessageIndex] = {
          ...assistantMessage,
          content: newContent,
        };

        return updatedMessages;
      });
    };

    newEventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
      newEventSource.close();
      setEventSource(null);
    };
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("chat_image[role]", "user");
    formData.append("chat_image[image]", file);

    try {
      const response = await axios.post(
        `/chats/${props.chat.id}/chat_images`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${props.formToken}`,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      setImageURL(response.data.imageURL);
      setImageId(response.data.imageId);
    } catch (error) {
      console.error("Image upload failed:", error);
    }
  };

  useEffect(() => {
    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [eventSource]);

  return (
    <div className="mx-auto p-4">
      {props.chat.chatType === "story_telling" ? (
        <StoryTellingChat
          formToken={props.formToken}
          onSaveChat={() => setIsStorySubmitted(true)} 
          chatId={props.chat.id}
          chatStory={props.chat.chatStory} 
        />
      ) : (
        <div className="mb-4 h-[600px] flex flex-col items-end overflow-y-auto">
          {messages.map((message, messageIndex) => (
            <Message message={message} key={`${messageIndex}-messages`} />
          ))}
        </div>
      )}
      {!props.readOnly &&
      props.chat.chatType !== "story_telling" &&
      !isStorySubmitted ? (
        <MessageInput
          handleSend={handleSend}
          input={input}
          setInput={setInput}
          handleFileChange={handleFileChange}
        />
      ) : null}
    </div>
  );
};

Chat.propTypes = {
  chatKey: PropTypes.number,
  name: PropTypes.string,
  chat: PropTypes.shape({
    id: PropTypes.number.isRequired,
    chatType: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        role: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        image_url: PropTypes.string,
      })
    ).isRequired,
    chatStory: PropTypes.shape({
      id: PropTypes.number.isRequired,
      story: PropTypes.string, // It can be null initially
      images: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          role: PropTypes.string,
          imageUrl: PropTypes.string.isRequired,
          imagePrompt: PropTypes.string.isRequired,
        })
      ).isRequired,
      prompt: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Chat;
