import React from'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import routes from './routes.jsx'
import {BsArrowLeft} from "react-icons/bs";
import HelloWorld from '../bundles/HelloWorld/components/HelloWorld.jsx';

const GoBackSection = () => {
  return <div >
    <Link className="flex gap-2 items-center" to="../" >
      <BsArrowLeft />
      Go back
    </Link>
  </div>
}

const RouterApp = (props, railsContext) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <main>
            <section className="mb-8 bg-blue-200 p-6 rounded-lg shadow-lg">
              <h2 className="text-3xl font-semibold mb-4 text-center text-blue-900">About Our School</h2>
              <h1>Hello world Component:</h1>
              <HelloWorld name="Tyler" title="Hello World" />
              <p className="text-lg">
                Our school is dedicated to providing a nurturing environment where kids can learn, play, and thrive.
                We believe in a balanced approach to education, combining academic excellence with fun activities.
              </p>
            </section>
            <section className="mb-8 bg-green-200 p-6 rounded-lg shadow-lg">
              <h2 className="text-3xl font-semibold mb-4 text-center text-green-900">Activities</h2>
              <ul className="list-disc list-inside text-lg space-y-2 text-blue-900">
                <span className="text-black">SPA navigation:</span>
                <li className="hover:text-blue-700">
                  <Link to={"/"}>home</Link></li>
                <li className="hover:text-blue-700">
                  <Link to={"/about"}>about</Link></li>
                <li className="hover:text-blue-700">
                  <Link to={"/services"}>services</Link></li>
                <li className="hover:text-blue-700">
                  <Link to={"/contact"}>contact</Link></li>
              </ul>
            </section>
            <section className="bg-red-200 p-6 rounded-lg shadow-lg">
              <h2 className="text-3xl font-semibold mb-4 text-center text-red-900">Contact Us</h2>
              <p className="text-lg text-center">
                Have questions? Reach out to us at{' '}
                <a href="mailto:info@schoolapp.com" className="text-red-500 underline">
                  info@schoolapp.com
                </a>
                .
              </p>
            </section>
          </main>
        } />
        <Route path="/about" element={
          <section className="bg-orange-100 p-6 rounded-lg shadow-lg">
            <GoBackSection />
            <h1>ABOUT SECTION</h1>
          </section>
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterApp