import React, { useEffect } from 'react';
import LogRocket from 'logrocket';

const LogrocketWrapper = ({ user }) => {
  useEffect(() => {
    if (!window.LogRocketInitialized) {
      LogRocket.init('sgko6z/tyb');
      window.LogRocketInitialized = true;
    }
    
    if (user) {
      LogRocket.identify(user.id, {
        name: user.fullname,
        email: user.email
      });
    }
  }, [user]);

  return null;
};

export default LogrocketWrapper;
