import React from 'react';
import { Route, Routes } from 'react-router-dom';

export default (
    <div>
        <Routes>
            <Route path="/" element={<div>page "/"</div>} />
            <Route path="/test" element={<div>page "/test"</div>} />
        </Routes>
    </div>
);
